import React, { useEffect, useRef } from 'react';
import { globalStore } from '~/store';
import { getHash } from '~/utils';
import JsonView from 'react18-json-view';
import 'react18-json-view/src/style.css';
const CanvasComponent: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [canvasData, setCanvasData] = React.useState<{ hash: string; data: any } | null>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      const context = canvas.getContext('2d');
      if (context) {
        context.font = '48px serif';
        const text = 'LeaksRadar';
        context.font = '30px Arial';
        context.shadowColor = 'violet';
        context.shadowBlur = 15;
        context.shadowOffsetX = 5;
        context.shadowOffsetY = 5;
        context.fillStyle = '#069';
        context.fillText(text, 2, 50);
        context.fillStyle = 'rgba(102, 204, 0, 0.7)';
        context.fillText(text, 4, 50);

        const dataURL = canvas.toDataURL();
        const width = canvas.width;
        const height = canvas.height;
        const size = dataURL.length;

        const data = { width, height, size };

        const fetchHash = async () => {
          const hash = await getHash(dataURL);
          setCanvasData({ hash, data });
          globalStore.set({ ...globalStore.get(), canvas: { hash, data } });
        };

        fetchHash();
      }
    }
  }, []);

  return (
    <div>
      <h2 className="text-3xl font-medium mb-5 flex items-center gap-2">
        Canvas <span className="text-sm border px-1">{canvasData?.hash.slice(0, 12)}</span>
      </h2>
      <div className="overflow-auto border p-2 rounded-lg border-slate-200 h-auto max-h-[500px]">
        <canvas ref={canvasRef} width={200} height={100} />
        <JsonView src={canvasData} />
      </div>
      
    </div>
  );
};

export default CanvasComponent;
